import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import test from "../../images/test.svg";

const IndexPage = (props) => {
  const [blogs, setBlogs] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              slug
              path
              title
              image
            }
          }
        }
      }

      shopifyImage: file(relativePath: { eq: "woman.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      artImage: file(relativePath: { eq: "art.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      helpImage: file(relativePath: { eq: "help.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motivatedImage: file(relativePath: { eq: "motivated.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      person1Image: file(relativePath: { eq: "person1.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      person2Image: file(relativePath: { eq: "person2.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      person3Image: file(relativePath: { eq: "person3.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      homeImage: file(relativePath: { eq: "good.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [elements, setElements] = useState([]);

  useEffect(() => {
    setElements([
      {
        id: "1",
        type: "input",
        data: { label: "An input node", image: test },
        position: { x: 0, y: 50 },
        sourcePosition: "right",
      },
      {
        id: "2",
        type: "selectorNode",
        style: { border: "1px solid #777", padding: 10 },
        position: { x: 300, y: 50 },
      },
      {
        id: "3",
        type: "output",
        data: { label: "Output A" },
        position: { x: 650, y: 25 },
        targetPosition: "left",
      },
      {
        id: "4",
        type: "output",
        data: { label: "Output B" },
        position: { x: 650, y: 100 },
        targetPosition: "left",
      },

      {
        id: "e1-2",
        source: "1",
        target: "2",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2a-3",
        source: "2",
        target: "3",
        sourceHandle: "a",
        animated: true,
        style: { stroke: "#fff" },
      },
      {
        id: "e2b-4",
        source: "2",
        target: "4",
        sourceHandle: "b",
        animated: true,
        style: { stroke: "#fff" },
      },
    ]);
  }, []);

  console.log("Markdown language");
  console.log(data.allMarkdownRemark);

  useEffect(() => {
    setBlogs(() => {
      return [...data.allMarkdownRemark.edges];
    });
  }, [data.allMarkdownRemark]);

  if (data.allMarkdownRemark) {
    return (
      <Layout>
        <hr />
        <section class="text-gray-600 body-font">
          <div class="container px-5 py-24 mx-auto">
            <div class="flex flex-wrap -m-4">
              {data.allMarkdownRemark.edges.map(({ node }) => {
                const {
                  frontmatter: { slug, title, description },
                } = node;
                console.log(slug);

                try {
                  if(slug.split("/")[1] !== "blog"){
                    return null
                  }
                } catch (error) {
                  return null
                }

                return (
                  <div class="p-4 md:w-1/3">
                    <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                      <img
                        class="lg:h-48 md:h-36 w-full object-cover object-center"
                        src="/blog/how_to_sell_on_shopify.png"
                        alt="blog"
                      />
                      <div class="p-6">
                        <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                          CATEGORY
                        </h2>
                        <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                          {title}
                        </h1>
                        <p class="leading-relaxed mb-3">{description}</p>
                        <div class="flex items-center flex-wrap ">
                          <Link
                            to={`${slug}`}
                            class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0"
                          >
                            Learn More
                            <svg
                              class="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  return null;
};
export default IndexPage;
